// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");



$(document).ready(function ()
{



	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		buttons: ['close']
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('data-src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// File upload
	bsCustomFileInput.init()
});

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});

$slider_slprotect_home = $('#slider-slprotect_home');

var $status = $('#slider-count');

$slider_slprotect_home.on('init reInit afterChange slickFilter slickUnfilter', function (event, slick, currentSlide, nextSlide) {
	// no dots -> no slides
	if(!slick.$dots || slick.$slides.length <= 2){
		$status.text('');
		return;
	}

	//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
	var i = (currentSlide ? currentSlide : 0) + 1;
	// use dots to get some count information
	$status.text(i + '/' + (slick.$slides.length));
});

$slider_slprotect_home.slick({
	adaptiveHeight: true,
	prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
	nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	autoplay: true,
	autoplaySpeed: 2800,
	arrows: false,
	dots: true,
	infinite: true,
	speed: 1200,
	slidesToShow: 1,
	slidesToScroll: 1,
	centerMode: false,
	variableWidth: false,
	appendDots : $('#slider-slprotect_home'),
	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: false,
			}
		},
	]
});

$('.filter-link').on('click', function(e) {
	$('.filter-link').removeClass('active');
	$(e.target).addClass('active');
	var filter = $(e.target).attr('data-filter');

	$('#slider-realisaties').slick('slickUnfilter');
	if (filter != '*') {
		$('#slider-realisaties').slick('slickFilter', function(i, item) {
			var $found = $(filter, $(item));

			if ($found.length == 0) {
				return false;
			} else {
				return true;
			}
		});
	}
});






// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
